import React from "react"
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

type PropsType = {
    className?: string
}

export const IconArrowRight: React.FC<PropsType> = ({className}) => {
    let currentAlgorithm = useSelector(
        (state: RootState) => state.designMode.currentAlgorithm
    );

    let classNames: Array<string> = ['customIcon', currentAlgorithm]
    if(className) classNames.push(className)
    
      return <svg className={classNames.join(' ')} width="5" height="9" viewBox="0 0 5 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M0.580516 0.676829C0.87341 0.383935 1.42743 0.463081 1.81795 0.853605L4.64638 3.68203C5.0369 4.07256 5.11605 4.62658 4.82316 4.91947C4.53026 5.21236 3.97624 5.13322 3.58572 4.74269L0.757293 1.91427C0.366768 1.52374 0.287623 0.969722 0.580516 0.676829Z" fill="#2B3858"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M4.82317 4.08002C5.11606 4.37291 5.03692 4.92693 4.64639 5.31746L1.81797 8.14589C1.42744 8.53641 0.873424 8.61556 0.58053 8.32266C0.287637 8.02977 0.366783 7.47575 0.757307 7.08523L3.58573 4.2568C3.97626 3.86627 4.53028 3.78713 4.82317 4.08002Z" fill="#2B3858"/>
      </svg>
}

