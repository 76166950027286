import { Button, DatePicker, Input, Popconfirm, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useDeleteChatMutation, useGetAllChatsQuery } from "../../../store/webchatApiSlice";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import { IconCross } from "../../../Components/Icons/IconCross";
import "./AdminHistoryVizardGenerations.scss";
import { Loader } from "../../../Components/common/Loader";
import { LoadingOutlined, QuestionCircleOutlined, SearchOutlined } from "@ant-design/icons";
import { User } from "../../../types/User.type";
import { UserAvatar } from "../../../Components/common/UserAvatar";
import { IconLinearEye } from "../../../Components/Icons/IconLinearEye";
import { AdminHistoryTextDialog } from "./AdminHistoryTextDialog";
import { NeuronetsSelector } from "../../../Components/common/NeuronetsSelector";
import { ColumnsType } from "antd/es/table";
import UserSelector from "../../../Components/common/UserSelector";
import { Empty } from "../../../Components/common/Empty";

const { RangePicker } = DatePicker;

type PropsType = {
  showFull: boolean
}

export const AdminHistoryVizardGenerations: React.FC<PropsType> = ({showFull}) => {
  const navigate = useNavigate();
  let currentAlgorithm = useSelector(
    (state: RootState) => state.designMode.currentAlgorithm
  );
  const [ showItem, setShowItem] = useState<any>(null);
  
  const [neronet, setNeronet] = useState<any>();
  const [range, setRange] = useState<any>();
  const [q, setSearchQuery] = useState<string>();
  const [searchUser, setSearchUser] = useState<any>();
  const [favorites, setFavorites] = useState<boolean>(false);

  const [ deleteChatMutation ] = useDeleteChatMutation()
  const [pagination, setPagination] = useState<any>({
    total: 1,
    pageSize: 5,
    pageSizeOptions: [10, 20, 50, 100],
    current: 1,
  });
  const [tableSorter, setSorter] = useState<any>({
    field: "created_at",
    order: "descend",
  });
  const { data, isLoading } = useGetAllChatsQuery({
    page: pagination.current,
    limit: pagination.pageSize,
    type: "VIZARD",
    order: tableSorter.field,
    order_dir: tableSorter.order,
    q,
    from: (range ? range[0]?.toDate().getTime() : undefined),
    to: (range ? range[1]?.toDate().getTime() : undefined),
    favorites,
    neuronet_id: (neronet ? neronet.split('_')[0] : undefined),
    option_id: (neronet && neronet.split('_').length===2)?neronet.split('_')[1]:undefined,
    user_id: (searchUser ? searchUser : undefined)
  });
  useEffect(() => {
    setPagination({ ...pagination, pageSize: showFull ? 10 : 5 });
  }, [showFull]);
  useEffect(() => {
    if (data) {
      setPagination({ ...pagination, total: data[1] })
    }
  }, [data])

  const columns: ColumnsType = [
    {
      title: "id",
      dataIndex: "id",
      key: "id",
      sorter: true,
    },
    {
      title: "Пользователь",
      dataIndex: "user_id",
      key: "user_id",
      render: (text: string, record: any) => {
        const user: User = record.user;
        return <UserAvatar user={record.user} />
      },
    },
    {
      title: "Запрос",
      dataIndex: "text",
      key: "text",
      render: (text: string, record: any) => {
        return record.name
      }
    },
    {
      title: "Тип задачи",
      dataIndex: "neuronet",
      key: "neuronet",
      render: (text: string, record: any) => {
        if (record.option) {
          return (
            <div className="historyVizardGenerationsTableNeuronet">
              {record.option.image && (
                <img src={record.option.image.url} width="18px" alt="" />
              )}
              {record.option.name}
            </div>
          );
        } else if (record.neuronet) {
          return (
            <div className="historyVizardGenerationsTableNeuronet">
              {record.neuronet.image && (
                <img src={record.neuronet.image.url} width="18px" alt="" />
              )}
              {record.neuronet.name}
            </div>
          );
        }
      },
    },
    {
      title: "Дата",
      dataIndex: "created_at",
      key: "created_at",
      sorter: true,
      render: (text: string, record: any) => {
        return (
          <>
            <span>
              {new Date(record.created_at).toLocaleDateString()}
            </span>
            <span style={{ marginLeft: '8px' }}>
              {new Date(record.created_at).toLocaleTimeString()}
            </span>
          </>
        );
      }
    },
    {
      title: "",
      dataIndex: "operation",
      render: (text: string, record: any) => {
        return (
          <div className="historyVizardGenerationsTableOperations">
            {record.state ==='loading' && <span onClick={openChatDialog(record)}>
              <LoadingOutlined />
              </span>}
            {record.state !=='loading' && <span className="tableOperation" onClick={openChatDialog(record)}>
              <IconLinearEye />
            </span>
            }
            <span className="tableOperation">
              <Popconfirm style={{width: '200px'}}
                title="Удалить генерацию? Вы уверены?"
                placement="topRight"
                onConfirm={deleteChat(record)}
                icon={<QuestionCircleOutlined style={{ color: "red" }} />}>
                  <div>
                    <IconCross />
                  </div>
              </Popconfirm>
            </span>
          </div>
        );
      },
    },
  ];

  const changedTable = (
    pagination: any,
    filters: any,
    sorter: any,
    extra: any
  ) => {
    setPagination(pagination);
    if (sorter.field) {
      setSorter({ ...tableSorter, field: sorter.field, order: sorter.order });
    }
  };

  const deleteChat = (item: any) => () => {
    deleteChatMutation({chat_id: item.id})
  }
  const openChatDialog = (item: any) => () => {
    // setShowItem(item)
    navigate(`/admin/history/video/${item.vizard.id}`)
  }
  const setDateRange = (e: any) => {
    setRange(e);
  };
  if(isLoading) return <Loader />

  return (
    <div className="adminHistoryVizardGenerations">
      {!showFull && <div className="historyVizardGenerationsHeader">
        <span>Работа с видео</span>
        <span>
          <Button disabled={!isLoading && data && data[1]===0} onClick={()=>navigate('/admin/history/video')}>Смотреть все генерации</Button>
        </span>
      </div>
      }
      {showFull && (
        <div className={"historyVizardGenerationsFilters "+currentAlgorithm}>
          <div className="historyVizardGenerationsFiltersSelector">
            <span>Задача</span>
            <span>
              <NeuronetsSelector
                value={neronet}
                onChange={(value) => {
                  setNeronet(value);
                }}
              />
            </span>
          </div>
          <div className="historyVizardGenerationsFiltersDatePicker">
            <span>Период</span>
            <span>
              <RangePicker value={range} onChange={setDateRange} />
            </span>
          </div>
          <div  className="historyVizardGenerationsFiltersUsers">
            <span>
              Пользователи
              </span>
              <span>
            <UserSelector value={searchUser} onChange={setSearchUser} />
              </span>
          </div>
          <div  className="historyVizardGenerationsFiltersQuery">
            <span>
              Поиск
              </span>
              <span>
            <Input.Search placeholder="Поиск"
              size="middle"
              allowClear={true}
              value={q}
              onChange={(e) => { setSearchQuery(e.target.value) }}
              enterButton={<Button type="primary"><SearchOutlined /></Button>}
            />
              </span>
          </div>
        </div>
      )}
      { !isLoading && data && data[1]>0 &&
        <Table
          bordered={false}
          columns={columns}
          dataSource={data[0]}
          loading={isLoading}
          onChange={changedTable}
          pagination={pagination} 
          className={
            "historyVizardGenerationsTable " +
            (currentAlgorithm === "dark" ? "table_dark" : "")
          }
          rowKey={(record) => record.id}></Table>
      }
      { !isLoading && data && data[1]===0 &&
        <Empty />
      }
    </div>
  );
};
