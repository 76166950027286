// import { Popconfirm, Table, Tooltip } from "antd";
import React from "react";
import { transformGetCreateChatsOptionsQueryData, useGetCreateChatsOptionsQuery } from "../../../store/webchatApiSlice";

import { useParams } from "react-router-dom";
import { Card, Flex } from "antd";
import { LkChatCard } from "../../../Components/lk/LkChatCard";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";

export const LkTextChatsList: React.FC = () => {
  const params = useParams();
  let currentAlgorithm = useSelector(
    (state: RootState) => state.designMode.currentAlgorithm
  );

  const { data: createChatData } = useGetCreateChatsOptionsQuery({
    output_type: params.type,
  });

  
  const dataText = transformGetCreateChatsOptionsQueryData(createChatData, ["text"]);

  return (
    <div className={"lkDashBoard " + currentAlgorithm}>
      <Flex style={{ margin: "12px" }}>
        {dataText && (
            <Flex style={{ gap: "24px" }} wrap={true}>
              {dataText.map((item: any) => {
                return (
                  <LkChatCard key={item.key} data={item} parent_id={null} />
                );
              })}
            </Flex>
        )}
      </Flex>
    </div>
  );
};
