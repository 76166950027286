import { Button, Modal } from "antd";
import React, { useState } from "react";
import {
  useGetPresentersQuery,
  useGetVoicesQuery,
} from "../../../store/didApiSlice";
import {
  DidAvatarVoiceValueType,
  PreseterItemType,
  VoiceItemType,
} from "../../../types/Did.type";
import "./LkVideoDidLibrary.scss";
import { Loader } from "../../../Components/common/Loader";
import { PresenterItem } from "./PresenterItem";
import { VoiceItem } from "./VoiceItem";

type PropsType = {
  gender: string;
  value: DidAvatarVoiceValueType;
  onChange: (data: DidAvatarVoiceValueType) => void;
  close: () => void;
};

export const LkVideoDidLibrary: React.FC<PropsType> = ({
  gender,
  value,
  onChange,
  close,
}) => {
  const [avatar, setAvatar] = useState<string | null>(value.avatar);
  const [voice, setVoice] = useState<string | null>(value.voice);

  const { data: presenters, isLoading: isLoadingPresenters } = useGetPresentersQuery({});
  const { data: voices, isLoading: isLoadingVoices } = useGetVoicesQuery({});

  const handleOk = () => {

    onChange({
      avatar,
      voice,
    });
    close();
  };

  const handleAvatarChange = (item: PreseterItemType) => {
    setAvatar(item.presenter_id)
  }
  const handleVoiceChange = (item: VoiceItemType) => {
    setVoice(item.voice_id)
  }

  return (
    <Modal
      className="lkVideoDidLibraryModal"
      title={<div className="lkVideoDidLibraryTitle">
        {(gender === "M" ? "Мужская " : "Женская ") + "внешность"}
      </div>}
      footer={<><Button type="primary" onClick={handleOk}>Подтвердить</Button></>}
      getContainer={() => {
        return document.getElementById("modals_container") || document.body;
      }}
      open={true}
      width={800}
      height={'auto'}
      onOk={handleOk}
      onCancel={close}>
      <div className="lkVideoDidLibrary">

        <div className="lkVideoDidLibraryAvatarsContainer">
        
        {isLoadingPresenters && (
          <div className="lkVideoDidLibraryAvatarsLoader">
            <Loader />
          </div>
        )}
        {presenters && (
          <div className="lkVideoDidLibraryAvatars">
            {presenters.filter(
              (presenterItem: PreseterItemType)=>{ 
                return presenterItem.gender===(gender.toLowerCase()[0]==='m'?'male':'female')}
            ).map((presenterItem: PreseterItemType) => {
              return <PresenterItem item={presenterItem} onChange={handleAvatarChange} 
              selected={presenterItem.presenter_id===avatar}/>;
            })}
          </div>
        )}
        </div>
        <div className="lkVideoDidLibraryVoicesContainer">
          <div className="lkVideoDidLibraryVoicesTitle">
            {(gender === "M" ? "Мужской " : "Женский ") + "голос"}
          </div>
          <div className="lkVideoDidLibraryVoicesAlert">
            Перечень доступных для выбора голосов определяется в зависимости от
            выбранного аватара. Каждому аватару соответствует свой набор
            голосов.
          </div>
          {isLoadingVoices && (
            <div className="lkVideoDidLibraryVoicesLoader">
              <Loader />
            </div>
          )}
          <div className="lkVideoDidLibraryVoices">
            {voices &&
              voices.filter(
                (voiceItem: VoiceItemType)=>{ 
                  return voiceItem.gender===(gender.toLowerCase()[0]==='m'?'male':'female')}
              ).map((voiceItem: VoiceItemType) => {
                return <VoiceItem item={voiceItem} onChange={handleVoiceChange} 
                selected={voiceItem.voice_id===voice}/>;
              })}
          </div>
        </div>
      </div>
    </Modal>
  );
};
