import React, { useEffect, useRef, useState } from "react";
import { DidItemType } from "../../../types/Did.type";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import {
  FullscreenOutlined,
  PauseCircleFilled,
  PlayCircleFilled,
  PlayCircleOutlined,
  QuestionCircleOutlined,
  StepForwardOutlined,
} from "@ant-design/icons";
import { IconVolumeHigh } from "../../../Components/Icons/IconVolumeHigh";
import { closeVideoPlayer } from "../../../store/videoPlayerSlice";

import "./DidVideoPlayer.scss";
import { IconTimer } from "../../../Components/Icons/IconTimer";
import { IconDownload } from "../../../Components/Icons/IconDownload";
import {
  useDeleteDidItemMutation,
  useToggleDidFavoriteMutation,
} from "../../../store/didApiSlice";
import { IconStarSelected } from "../../../Components/Icons/IconStarSelected";
import { IconStar } from "../../../Components/Icons/IconStar";
import { Popconfirm } from "antd";
import { IconCross } from "../../../Components/Icons/IconCross";

export const DidVideoPlayer: React.FC = () => {
  const dispatch = useAppDispatch();

  const [deleteDidItemMutation] = useDeleteDidItemMutation();
  const [toggleDidFavoriteMutation] = useToggleDidFavoriteMutation();

  const url = useAppSelector((state) => state.videoPlayer.url);
  const type = useAppSelector((state) => state.videoPlayer.type);
  const item = useAppSelector((state) => state.videoPlayer.item);
  const openModal = useAppSelector((state) => state.videoPlayer.open);

  const togleFavorites = async () => {
    if (item) {
      await toggleDidFavoriteMutation({ id: item.id }).then(e=>{
        console.log(e)
        itemLocal && setItemLocal({...itemLocal, is_favorite: !itemLocal?.is_favorite})
      });
    }
  };
  const deleteDidProject = async () => {
    if (item) {
      await deleteDidItemMutation({ id: item.id });
    }
  };
  const downloadVideo = () => {
    if (item) {
      if (!item.result_video?.url) return;
      window.location.assign(item.result_video?.url);
    }
  };

  const playerRef = useRef<any>();
  const [state, setState] = useState<string>("stop");
  const [currentTime, setCurrentTime] = useState(0);
  const [percent, setPercent] = useState(0);
  const [duration, setDuration] = useState(0);
  const [ itemLocal, setItemLocal ] = useState<DidItemType|null>(item)

  useEffect(() => {
    if (!playerRef.current) return;
    if (state === "play") {
      playerRef.current.addEventListener("timeupdate", timeupdate);
      playerRef.current.addEventListener("ended", playend);
      playerRef.current.play();
    }
    if(state=== 'stop'){
        playerRef.current.pause();
        playerRef.current.currentTime = 0;

    }
    //  else {
      // if (playerRef.current) {
      // }
    // }
  }, [state]);
  const timeupdate = (e: any) => {
    setCurrentTime(e.target.currentTime);
    setPercent(Math.round((e.target.currentTime / e.target.duration) * 100));
    setDuration(e.target.duration);
  };
  const handleCloseModal = () => {
    setState('stop')
    playerRef.current.currentTime = 0;
    playerRef.current.removeEventListener("timeupdate", timeupdate);
    playerRef.current.removeEventListener("ended", playend);
    dispatch(closeVideoPlayer({}));
  };

  const playend = (e: any) => {
    // onStop && onStop()
    setState("stop");
    playerRef.current.currentTime = 0;
      
  };
  const hanldeClick = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const playerStartStop = () => {
    if (!playerRef.current) return;
    if (state==='play') {
      playerRef.current.pause();
      setState("pause");
    } 
    if (state==='pause') {
      playerRef.current.play();
      setState("play");
    }
    if (state==='stop') {
      playerRef.current.play();
      setState("play");
    }
  };
  const playerRewind = (e: any) => {
    let posx = 0;
    if (e.pageX || e.pageY) {
      posx = e.pageX;
    } else if (e.clientX || e.clientY) {
      posx = e.clientX;
    }
    const offsetx = posx - getOffsetX(e.target);
    const pc = (offsetx / e.target.clientWidth) * 100;

    playerRef.current.currentTime = (playerRef.current.duration / 100) * pc;
  };

  const requestFullScreen = () => {
    if (!playerRef.current) return;

    if (playerRef.current.fullscreenElement) {
      playerRef.current.exitFullscreen();
    } else if (playerRef.current.requestFullscreen) {
      playerRef.current.requestFullscreen();
    } else if (playerRef.current.mozRequestFullScreen) {
      playerRef.current.mozRequestFullScreen();
    } else if (playerRef.current.webkitRequestFullscreen) {
      playerRef.current.webkitRequestFullscreen();
    } else if (playerRef.current.msRequestFullscreen) {
      playerRef.current.msRequestFullscreen();
    }
  };

  const getOffsetX = (el: any): number => {
    if (el.offsetParent) return el.offsetLeft + getOffsetX(el.offsetParent);
    return el.offsetLeft;
  };

  const formatDate = (date: any): string => {
    return Intl.DateTimeFormat("ru-RU", {
      dateStyle: "short",
      timeStyle: "short",
    }).format(new Date(date));
  };

  const formatTime = (seconds: number, { short = true }): string => {
    const pad = (num: number) => (num < 10 ? `0${num}` : `${num}`);

    const H = pad(Math.floor(seconds / 3600));
    const i = pad(Math.floor((seconds % 3600) / 60));
    const s = pad(seconds % 60);

    if (short) {
      let result = "";
      if (parseInt(H) > 0) result += `${+H}:`;
      result += `${parseInt(H) > 0 ? i : +i}:${s}`;
      return result;
    } else {
      return `${H}:${i}:${s}`;
    }
  };

  if (type === "did") {
    return (
      <>
        {openModal && url && (
          <div className="didVideoPlayer">
            <div className="videoPlayerModalDid" onClick={handleCloseModal}>
              <div className="videoPlayerModalBody" onClick={hanldeClick}>
                <div className="videoPlayerModalBodyHeader">
                  <span>ID {item?.id}</span>
                  <span>Видео с аватаром</span>
                  <span>
                    <IconTimer />
                    &nbsp;&nbsp;
                    {formatDate(item?.created_at)}
                  </span>
                  <div onClick={handleCloseModal} className="videoPlayerModalBodyHeaderCloseBtn">
                    <IconCross />
                  </div>
                </div>

                {/* <div className="videoPlayerModalDidTitle">
                  <span>{item?.id}</span>
                </div> */}

                <div className="videoPlayerModalBodyPlayer">
                  <video ref={playerRef} controls={false} onClick={playerStartStop}>
                    <source src={url} type="video/mp4"></source>
                  </video>
                  <div className="videoPlayerModalBodyControlsContainer">
                    <div className="videoPlayerModalBodyControls">
                      <div
                        className="videoPlayerModalBodyPlayBtn"
                        onClick={playerStartStop}>
                        {(state === 'stop' || state === 'pause') && <PlayCircleFilled style={{ fontSize: "32px" }} />}
                        {state === 'play' && <PauseCircleFilled style={{ fontSize: "32px" }} />}
                      </div>
                      <div className="videoPlayerModalBodyCurrentTime">
                        {formatTime(Math.round(currentTime), { short: true })}
                      </div>
                      <div
                        className="videoPlayerModalBodyControlsProgress"
                        onClick={playerRewind}>
                        <div
                          className="videoPlayerModalBodyControlsProgressBar"
                          style={{ width: `${percent}%` }}></div>
                      </div>
                      <div className="videoPlayerModalBodyDurationTime">
                        {formatTime(Math.round(duration), { short: true })}
                      </div>

                      {/* <div>
                        <StepForwardOutlined style={{ fontSize: "32px" }} />
                      </div> */}
                      {/* <div>
                        <IconVolumeHigh />
                      </div> */}
                      <div>
                        <FullscreenOutlined
                          onClick={requestFullScreen}
                          style={{ fontSize: "32px" }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="videoPlayerModalDidContent">
                  <div className="videoPlayerModalDidContentLine">
                    <span>Продолжительность</span>
                    <span>
                      {formatTime(Math.round(duration), { short: true })}
                    </span>
                  </div>
                  <div className="videoPlayerModalDidContentLine">
                    <span>Внешность аватара:</span>
                    <span>{item?.presenter.title}</span>
                  </div>
                  <div className="videoPlayerModalDidContentLine">
                    <span>Голос аватара:</span>
                    <span>{item?.voice.title}</span>
                  </div>
                  <div className="videoPlayerModalDidContentLine">
                    <span>Загрузка водянного знака:</span>
                    <span>{item?.watermark_url ? "да" : "нет"}</span>
                  </div>
                  <div className="videoPlayerModalDidContentLine">
                    <span>Фоновое изображение:</span>
                    <span>{item?.source_image_url ? "да" : "нет"}</span>
                  </div>
                  <div className="videoPlayerModalDidContentLine">
                    <span>Формат видео: </span>
                    <span>{item?.format}</span>
                  </div>
                  <div className="videoPlayerModalDidContentLine">
                    <div className="textField">Текст для озвучки: {item?.text}</div>
                  </div>
                </div>
                <div className="videoPlayerModalDidButtons">
                  <div
                    className="didVideoCardButtonContainer"
                    onClick={togleFavorites}>
                    <span className="didVideoCardButton">
                      <div>
                        {itemLocal?.is_favorite ? (
                          <IconStarSelected />
                        ) : (
                          <IconStar />
                        )}
                      </div>
                    </span>
                  </div>
                  <div
                    className={
                      "didVideoCardButtonContainer" +
                      (!item?.result_video?.url ? " disabled" : "")
                    }
                    onClick={downloadVideo}>
                    <span className="didVideoCardButton">
                      <IconDownload />
                    </span>
                  </div>
                  <div className="didVideoCardButtonContainer">
                    <span className="didVideoCardButton">
                      <Popconfirm
                        style={{ width: "200px" }}
                        title="Удалить видео? Вы уверены?"
                        placement="topRight"
                        onConfirm={deleteDidProject}
                        icon={
                          <QuestionCircleOutlined style={{ color: "red" }} />
                        }>
                        <div>
                          <IconCross />
                        </div>
                      </Popconfirm>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }

  return null;
};
