import React, { useEffect, useRef, useState } from 'react';
import { useGetLogsQuery } from '../../../store/logsSlice';
import { Input, Table, DatePicker, InputRef, theme, TableColumnsType, Button, Flex } from 'antd';
import LogsDetails from './LogDetails';
import { EyeOutlined, SearchOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import UserSelector from '../../../Components/common/UserSelector';
import { useSelector } from 'react-redux';
import { RootState } from '../../../store/store';

import "./UserLogs.scss"
import { UserAvatar } from '../../../Components/common/UserAvatar';
import { User } from '../../../types/User.type';
const { RangePicker } = DatePicker;
interface DataType {
  id: number,
  date: string;
  name: string;
  description: string;
}

const UserLogs: React.FC = () => {
  const [range, setRange] = useState<any>()
  const [searchUser, setSearchUser] = useState<number>();
  let currentAlgorithm = useSelector((state: RootState) => state.designMode.currentAlgorithm)
  const { token: { colorText } } = theme.useToken();

  const [activeRecord, setActiveRecord] = useState<any>()
  const [pagination, setPagination] = useState<any>({ total: 1, pageSize: 20, pageSizeOptions: [10, 20, 50, 100], current: 1 })
  const [q, setSearchQuery] = useState<string>();

  const { data, error, isLoading } = useGetLogsQuery({
    page: pagination.current,
    limit: pagination.pageSize,
    q: q,
    from: range ? range[0]?.toDate().getTime() : undefined,
    to: range ? range[1]?.toDate().getTime() : undefined,
    user_id: searchUser
  })

  const downloadExcel = ()=>{
    const a = document.createElement('a')
    const params: {[key: string]: string|undefined} = {
      user_id: searchUser?searchUser.toString():undefined,
      q: q,
      from: range ? range[0]?.toDate().getTime() : undefined,
      to: range ? range[1]?.toDate().getTime() : undefined,
    }
    a.href = '/api/statistics/userlog/excel?'+
    Object.keys(params)
      .filter((key: string)=>params[key]!==undefined)
      .map((key: string)=>`${key}=${params[key]}`)
      .join('&')
    a.target = '_blank'
    a.click()
  }

  useEffect(() => {
    if (data) {
      setPagination({ ...pagination, total: data[1] })
    }
  }, [data])

  const columns:TableColumnsType<DataType>  = [
    {
      title: "Дата",
      dataIndex: "created_at",
      key: "created_at",
      filterSearch: true,
      render: (text: string, record: any) => {
        return (
          <>
            <span>
              {new Date(record.created_at).toLocaleDateString()}
            </span>
            <span style={{ marginLeft: '8px' }}>
              {new Date(record.created_at).toLocaleTimeString()}
            </span>
          </>
        );
      }
    },
    {
      title: "Пользователь",
      dataIndex: "user_id",
      key: "user_id",
      render: (text: string, record: any) => {
        const user: User = record.user;
        return <UserAvatar user={record.user} />
      },
    },
    {
      title: "Откуда запрос",
      dataIndex: "relation",
      key: "relation",
      render: (text: string)=>{
        return <>
        {text==='webchat' && <>ЛК</>}
        {text==='messages_chat' && <>Бот</>}
        </>
      }
    },
    {
      title: "Тип записи",
      dataIndex: "action",
      key: "action",
    },
    {
      title: "Описание",
      dataIndex: "description",
      key: "description",
    },
    {
      title: 'Действия',
      dataIndex: 'operation',
      render: (text: string, record: any) => {
        return (
          <div className="operations">
            <span>
              <EyeOutlined onClick={showDetails(record)} />
            </span>
          </div>
        );
      }
    },
  ];
  const changedTable = (e: any) => {
    setPagination(e)
  };
  const showDetails = (record: any) => () => {
    setActiveRecord(record)
  }
  const closeDetails = () => {
    setActiveRecord(undefined)
  }



  return (
    <>
      <div className="searchBar">
        <div className='searchBarLine' style={{width: '100%', display: 'flex', justifyContent: 'flex-start', gap: '12px' }}>
          <div  className='searchBarCell'>
          <span>
            Выберите даты
          </span>
          <span>
            <RangePicker value={range} onChange={setRange} />
          </span>
          </div>
          <div  className='searchBarCell'>
          <span>
            Пользователь 
          </span>
          <span>
            <UserSelector onChange={setSearchUser} value='' />
          </span>
          </div>
          <div className='searchBarCell'>
          <span>
            Поиск
          </span>
          <span>

            <Input.Search placeholder="Поиск"
              size="middle"
              allowClear={true}
              value={q}
              onChange={(e) => { setSearchQuery(e.target.value) }}
              enterButton={<Button type="primary"><SearchOutlined /></Button>}
            />
          </span>

          </div>
          <div  className='searchBarCell'>
          <span>
            <Button onClick={downloadExcel}>Получить в формате Excel</Button>
          </span>

          </div>
        </div>
      </div>
      {!isLoading && data &&
        <Table
          bordered
          columns={columns}
          dataSource={data[0]}
          pagination={pagination}
          loading={isLoading}
          onChange={changedTable}
          rowKey={record => record.id}
          className={currentAlgorithm}
          style={{color: colorText}}
          ></Table>
      }
      {activeRecord &&
        <LogsDetails record={activeRecord} close={closeDetails} />
      }
    </>
  )
}

export default UserLogs;