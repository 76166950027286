import React from "react"
import { PreseterItemType } from "../../../types/Did.type"

type PropsType = {
  item: PreseterItemType,
  selected: boolean,
  onChange: (item: PreseterItemType)=>void
}

export const PresenterItem: React.FC<PropsType> = ({item, onChange, selected})=>{
 
  const handleClick = ()=>{
    onChange(item)
  }

  return <div className={"lkVideoDidLibraryAvatar "+(selected?' selected':'')} onClick={handleClick}>
    <div className="lkVideoDidLibraryAvatarImgContainer">
  <div className="lkVideoDidLibraryAvatarImg"  style={{backgroundImage: `url(${item.image_url})`}}></div>
    </div>
    <div className="lkVideoDidLibraryAvatarTitle">{item.title}</div>
  </div>
}