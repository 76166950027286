import React from "react"
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

type PropsType = {
    className?: string
}

export const IconArrowLeft: React.FC<PropsType> = ({className}) => {
    let currentAlgorithm = useSelector(
        (state: RootState) => state.designMode.currentAlgorithm
    );

    let classNames: Array<string> = ['customIcon', currentAlgorithm]
    if(className) classNames.push(className)
    
      return <svg className={classNames.join(' ')}  width="5" height="9" viewBox="0 0 5 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M4.41948 8.32317C4.12659 8.61606 3.57257 8.53692 3.18205 8.14639L0.35362 5.31797C-0.0369044 4.92744 -0.11605 4.37342 0.176843 4.08053C0.469736 3.78764 1.02376 3.86678 1.41428 4.25731L4.24271 7.08573C4.63323 7.47626 4.71238 8.03028 4.41948 8.32317Z" fill="#2B3858"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M0.176829 4.91998C-0.116065 4.62709 -0.0369189 4.07307 0.353605 3.68254L3.18203 0.854115C3.57256 0.463591 4.12658 0.384444 4.41947 0.677338C4.71236 0.970231 4.63322 1.52425 4.24269 1.91477L1.41427 4.7432C1.02374 5.13373 0.469722 5.21287 0.176829 4.91998Z" fill="#2B3858"/>
      </svg>
}

