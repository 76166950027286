// import { Popconfirm, Table, Tooltip } from "antd";
import React from "react";

import { useParams } from "react-router-dom";
import { HistoryTextGenerations } from "./HistoryTextGenerations";
import { HistoryImageGenerations } from "./HistoryImageGenerations";
import { HistoryAudioGenerations } from "./HistoryAudioGenerations";
import { HistoryVizardGenerations } from "./HistoryVizardGenerations";
import { HistoryDidGenerations } from "./HistoryDidGenerations";

export const History: React.FC = () => {
  const { type } = useParams()

  return (
    <>
    {(type==='text' || !type) && <HistoryTextGenerations showFull={type==='text'} /> }
    {(type==='images' || !type) && <HistoryImageGenerations showFull={type==='images'} />}
    {(type==='audio' || !type) && <HistoryAudioGenerations showFull={type==='audio'} />}
    {(type==='video' || !type) && <HistoryVizardGenerations showFull={type==='video'} />}
    {(type==='did' || !type) && <HistoryDidGenerations showFull={type==='did'} />}
    </>
  );
};
