import React from "react";
import { VoiceItemType } from "../../../types/Did.type";
import { Button } from "antd";
import { IconAudio } from "../../../Components/Icons/IconAudio";
import { SoundPlayer } from "../../../Components/SoundPlayer/SoundPlayer";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import {
  getPlayState,
  setPlayState,
  setPlayStop,
} from "../../../store/audioPlayerSlice";

type PropsType = {
  item: VoiceItemType;
  onChange: (item: VoiceItemType) => void;
  selected: boolean;
};

export const VoiceItem: React.FC<PropsType> = ({
  item,
  onChange,
  selected,
}) => {
  const dispatch = useAppDispatch();
  const playerState = useAppSelector((state) => getPlayState(state, item.id));

  const stopPlayer = () => {
    dispatch(setPlayStop(null));
  };
  const playAudio = (item: any) => () => {
    if (playerState === "idle") {
      dispatch(setPlayState(item.id));
    } else {
      dispatch(setPlayStop(null));
    }
  };

  const handleChange = () => {
    onChange(item);
  };

  return (
    <div className={"lkVideoDidLibraryVoice" + (selected ? " selected" : "")}>
      <div className="lkVideoDidLibraryVoiceTitle" onClick={handleChange}>
        {item.title}
      </div>
      <div>
        {item.ru_example_audio && (
          <>
            <div className="lkVideoDidLibraryVoiceAudio" onClick={playAudio(item)}>
              <IconAudio />
              <SoundPlayer
                url={item.ru_example_audio}
                state={playerState}
                onStop={stopPlayer}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
};
