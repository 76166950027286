import React, { useEffect, useState } from "react";
import { useDeleteUserMutation, useGetUsersQuery, userApiSlice, useUpdateUserLightVersionMutation, useUpdateUserStatusMutation } from "../../../store/userApiSlice";
import { Button, Input, Popconfirm, Switch, Table, Tooltip } from "antd";

import './Users.scss'
import UserEditor from "./UserEditor";
import { setEditedUser } from "../../../store/userSlice";
import { UpdateUsersLightVersionType, UpdateUsersStatusType, User } from "../../../types/User.type";
import { RootState, useAppDispatch } from "../../../store/store";
import { useDispatch, useSelector } from "react-redux";
import { Gender } from "../../../types/Gender.enum";
import { UserRoleEnum } from "../../../types/UserRole.enum";
import { CheckSquareOutlined, CloseSquareOutlined, DeleteOutlined, FormOutlined, InfoCircleOutlined, PlusOutlined, QuestionCircleOutlined, SearchOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import { StatusUserEnum } from "../../../types/Users.type";
import { RoleTag } from "../../../Components/common/RoleTag";
import { ColumnType } from "antd/lib/table";
import { UserAvatar } from "../../../Components/common/UserAvatar";
import { profile } from "console";
import { UserStatus } from "../../../Components/common/UserStatus";

const Users: React.FC = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  let currentAlgorithm = useSelector((state: RootState) => state.designMode.currentAlgorithm)

  const [openEditor, setOpenEditor] = useState<boolean>(false);
  const [editUserId, setEditUserId] = useState<number | null>(null);
  // const [users, setUsers] = useState<[User]>();
  // const { refetch } = userApiSlice.endpoints.getUsers.useQuerySubscription()
  const [q, setSearchQuery] = useState<string>();
  const [tableSorter, setSorter] = useState<any>({
    field: "created_at",
    order: "descend",
  });
  const [pagination, setPagination] = useState<any>({ total: 1, pageSize: 20, pageSizeOptions: [10, 20, 50, 100], current: 1 })
  const { data, error, isLoading, refetch } = useGetUsersQuery({
    page: pagination.current,
    limit: pagination.pageSize,
    order: tableSorter.field,
    order_dir: tableSorter.order,
    q: q
  });

  const [deleteUser, deleteResult] = useDeleteUserMutation()
  const [updateUserStatus, updateStatusResult] = useUpdateUserStatusMutation()
  const [updateUserLightVersion, updateUserLightVersionResult] = useUpdateUserLightVersionMutation()


  useEffect(() => {
    if (data) {
      setPagination({ ...pagination, total: data[1] })
    }
  }, [data])


  const newUser: User = {
    id: null,
    phone_number: '',
    role: UserRoleEnum.Member,
    profile: {
      last_name: "",
      first_name: "",
      gender: Gender.MALE
    },
    status: StatusUserEnum.REG_STARTED
  }

  const acceptUser = (user: User) => () => {

    const payload: UpdateUsersStatusType = {

      phone_numbers: [user.phone_number],
      status: StatusUserEnum.ACCOUNT_ACCEPTED
    }
    updateUserStatus(payload)

  }
  const rejectUser = (user: User) => () => {
    const payload: UpdateUsersStatusType = {
      phone_numbers: [user.phone_number],
      status: StatusUserEnum.ACCOUNT_REJECTED
    }
    updateUserStatus(payload)
  }

  const onChangeLightVersion = (user: User) => {
    if(!!user.telegram_chat_id ) {
      const payload: UpdateUsersLightVersionType = {
        is_pro_version: !user.is_pro_version,
        telegram_chat_id: user.telegram_chat_id
      }
      updateUserLightVersion(payload)
    }
  }

  const columns: ColumnType<any>[] = [
    {
      title: "id",
      dataIndex: "id",
      key: "id",
      sorter: true
    },
    {
      title: "Пользователь",
      dataIndex: "profile.first_name",
      key: "user.id",
      render: (text: string, record: any) => {
        return (
          <div>
              <UserAvatar user={record} />
          </div>
        )
      },
    },
    {
      title: "Роль",
      dataIndex: "role",
      key: "role",
      sorter: true,
      render: (text: string, record: any) => {
        return (<RoleTag user={record} />)
      }
    },
    {
      title: "Номер телефона",
      dataIndex: "phone_number",
      key: "phone_number",
      render: (text: string, record: any)=>{
        return (<span className="table_phonenumber">
           <Tooltip title={record.phone_number}>
              <span>
              {record.phone_number}
              </span>

           </Tooltip>
        </span>)
      }
    },
    {
      title: "Статус",
      dataIndex: "status",
      key: "status",
      sorter: true,
      render: (text: string, record: any)=>{
        return <UserStatus user={record} />
      }
    },
    {
      title: '',
      dataIndex: 'operation',
      render: (text: string, record: any) => {
        return (
          <div className="operations">
            <span className="tableOperation">
              <Tooltip title='Редактировать'>
                <FormOutlined onClick={handleOpenEditor(record)} />
              </Tooltip>
            </span>
            <span className="tableOperation">
              <Popconfirm title="Вы уверены?" onConfirm={() => sureDelete(record)}
                icon={<QuestionCircleOutlined style={{ color: 'red' }} />} >
                <Tooltip title='Удалить пользователя'>
                  <DeleteOutlined color="#990000" />
                </Tooltip>
              </Popconfirm>
            </span>
            <span className="tableOperation">
              <InfoCircleOutlined onClick={() => navigate(`/admin/user/${record.id}`)} />
            </span>
            <span className="tableOperation">
              <CheckSquareOutlined onClick={acceptUser(record)} />
            </span>
            <span className="tableOperation">
            <CloseSquareOutlined onClick={rejectUser(record)} />
            </span>
            <span className="tableOperation" style={{width: '60px', paddingLeft: '12px'}}>
              <Switch title='Режим Про/Лайт' style={{maxWidth: '44px'}} checked={record.is_pro_version} disabled={!record.telegram_chat_id} onClick={( ) => onChangeLightVersion(record)}/>
            </span>
          </div>
        );
      }
    },
  ];
  const handleOpenEditor = (user: User | null) => () => {
    dispatch(setEditedUser(user))
    setOpenEditor(true)
  }
  const closeModal = () => {
    setOpenEditor(false)
    refetch()
  }
  const changedTable = (pagination: any,
    filters: any,
    sorter: any,
    extra: any) => {
    setPagination(pagination)
    if (sorter.field) {
      setSorter({ ...tableSorter, field: sorter.field, order: sorter.order });
    }
  };

  const sureDelete = (e: any) => {

    deleteUser({ id: e.id })
    refetch()
  }
  const onSearch = (e: any) => {
    setSearchQuery(e)
  }

  return (
    <>
      <div className="searchBar">
          <span>
            <Input.Search placeholder="Поиск"
              size="large"
              allowClear
              enterButton={<Button type="primary"><SearchOutlined /></Button>}
              onSearch={onSearch}
            />
          </span>
          <span>
            <Button 
            type="primary"
              icon={<PlusOutlined />}
              onClick={handleOpenEditor(newUser)}>
              Добавить пользователя
            </Button>
          </span>
      </div>
      <Table
        bordered={false}
        columns={columns}
        dataSource={data?data[0]:[]}
        pagination={pagination}
        loading={isLoading}
        onChange={changedTable}
        className={'users_table '+(currentAlgorithm === 'dark' ? 'table_dark' : "")}
        rowKey={record => record.id}></Table>
      {openEditor &&
        <UserEditor modalVisible={openEditor} userId={editUserId} closeModal={closeModal} />
      }

    </>
  );
};

export default Users;
