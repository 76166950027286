import {
  Button,
  DatePicker,
  Pagination,
  Popconfirm,
  Switch,
  Table,
} from "antd";
import React, { useEffect, useState } from "react";
import {
  useDeleteChatMutation,
  useGetChatsQuery,
  useToggleFavoriteMutation,
} from "../../../store/webchatApiSlice";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";

import { IconStar } from "../../../Components/Icons/IconStar";
import { IconCross } from "../../../Components/Icons/IconCross";
import "./HistoryDidGenerations.scss";
import { Loader } from "../../../Components/common/Loader";
import { IconStarSelected } from "../../../Components/Icons/IconStarSelected";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { ColumnsType } from "antd/es/table";
import { NeuronetsSelector } from "../../../Components/common/NeuronetsSelector";
import { Empty } from "../../../Components/common/Empty";
import { DidVideoCard } from "../did/DidVideoCard";
import { useGetDidProjectsListQuery } from "../../../store/didApiSlice";
import { DidVideoPlayer } from "../did/DidVideoPlayer";
const { RangePicker } = DatePicker;

type PropsType = {
  showFull: boolean;
};

export const HistoryDidGenerations: React.FC<PropsType> = ({ showFull }) => {
  const navigate = useNavigate();
  let currentAlgorithm = useSelector(
    (state: RootState) => state.designMode.currentAlgorithm
  );
  const [favorites, setFavorites] = useState<boolean>(false);
  const [neronet, setNeronet] = useState<any>();
  const [range, setRange] = useState<any>();
  const [q, setSearchQuery] = useState<string>();
  const [deleteChatMutation] = useDeleteChatMutation();
  const [toggleFavoriteMutation] = useToggleFavoriteMutation();
  const [pagination, setPagination] = useState<any>({
    total: 1,
    pageSize: showFull ? 10 : 5,
    pageSizeOptions: [10, 20, 50, 100],
    current: 1,
  });
  const [tableSorter, setSorter] = useState<any>({
    field: "created_at",
    order: "descend",
  });
  const { data, isLoading, isError } = useGetDidProjectsListQuery({
    page: pagination.current,
    limit: pagination.pageSize,
    from: range ? range[0]?.toDate().getTime() : undefined,
    to: range ? range[1]?.toDate().getTime() : undefined,
    favorites,
  });

  // const { data, isLoading } = useGetChatsQuery({
  //   page: pagination.current,
  //   limit: pagination.pageSize,
  //   output_type: "video",
  //   order: tableSorter.field,
  //   order_dir: tableSorter.order,
  //   q,
  //   from: range ? range[0]?.toDate().getTime() : undefined,
  //   to: range ? range[1]?.toDate().getTime() : undefined,
  //   favorites,
  //   neuronet_id: neronet ? neronet.split('_')[0] : undefined,
  //   option_id: (neronet && neronet.split('_').length===2)?neronet.split('_')[1]:undefined
  // });
  useEffect(() => {
    setPagination({ ...pagination, pageSize: showFull ? 10 : 5 });
  }, [showFull]);
  useEffect(() => {
    if (data) {
      setPagination({ ...pagination, total: data[1] });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  // const onclick = (chat: any)=>()=>{
  //   navigate(`/lk/video/vizard/${chat.vizard.id}`)
  // }

  const toggleFavorite = (item: any) => () => {
    toggleFavoriteMutation({ chat_id: item.id });
  };

  const deleteChat = (item: any) => () => {
    deleteChatMutation({ chat_id: item.id });
  };
  const setDateRange = (e: any) => {
    setRange(e);
  };
  if (!data) return <Loader />;

  return (
    <div className={"historyDidGenerations " + (showFull ? "showFull" : "")}>
      {!showFull && (
        <div className="historyDidGenerationsHeader">
          <span>Генерция видео с аватаром</span>
          <span>
            <Button
              disabled={!isLoading && data && data[1] === 0}
              onClick={() => navigate("/lk/history/did")}>
              Смотреть все генерации
            </Button>
          </span>
        </div>
      )}
      {showFull && (
        <div className={"historyDidGenerationsFilters " + currentAlgorithm}>
          <div className="historyDidGenerationsFiltersSelector">
            <span>Задача</span>
            <span>
              <NeuronetsSelector
                value={neronet}
                onChange={(value) => {
                  setNeronet(value);
                }}
              />
            </span>
          </div>
          <div className="historyDidGenerationsFiltersDatePicker">
            <span>Период</span>
            <span>
              <RangePicker value={range} onChange={setDateRange} />
            </span>
          </div>
          <div className="historyDidGenerationsFiltersCheckboxes">
            <Switch
              checked={favorites}
              onChange={() => setFavorites(!favorites)}
            />
            <span>Избранное</span>
          </div>
        </div>
      )}
      {!isLoading && data && data[1] > 0 && (
        <>
          {!isLoading && data && data[1] > 0 && (
            <>
              <div className={"didGenerations"}>
                <div className={"didGenerationsCards"}>
                  {data &&
                    data[0] &&
                    data[0].map((item: any) => {
                      return (
                        <DidVideoCard mode="user" key={item.id} item={item} />
                      );
                    })}
                </div>
              </div>
              <Pagination
                onChange={(page: number, pageSize: number) => {
                  setPagination({ ...pagination, page, limit: pageSize });
                }}
                align="end"
                defaultCurrent={pagination.page}
                pageSize={pagination.limit}
                total={data[1]}
                pageSizeOptions={pagination.pageSizeOptions}
              />
            </>
          )}
        </>
      )}
      {!isLoading && data && data[1] === 0 && <Empty />}
      <DidVideoPlayer />
    </div>
  );
};
