import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";
import { DidItemType } from "../types/Did.type";

type VideoPlayerStateType = {
  open: boolean
  url: string | null
  type: 'vizard'|'did',
  item: DidItemType | null
}

const initialState: VideoPlayerStateType = {
  open: false,
  url: null,
  type: 'vizard',
  item: null
}

const videoPlayerSlice = createSlice({
    name: 'videoPlayer',
    initialState,
  reducers: {
    openVideoPlayer: (state, action)=>{
      state.open = true
      state.url = action.payload.url
      state.type = action.payload.type || 'vizard'
      state.item = action.payload.item || null
    },
    closeVideoPlayer: (state, action)=>{
      state.open = false
      state.url = null
    }
  }
})

export const { openVideoPlayer, closeVideoPlayer} = videoPlayerSlice.actions
export default videoPlayerSlice.reducer
