import { Tabs } from "antd";
import React from "react";
import { LKVideoVizardCreateProject } from "./LKVideoVizardCreateProject";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { LkVideoDid } from "../did/LkVideoDid";
import { LKVideoVizardView } from "./LKVideoVizardView";

const TabChildren: React.FC = ()=>{
  const location = useLocation()
  return <Routes> 
    <Route path="vizard" element={<LKVideoVizardCreateProject />} />
    <Route path="vizard/:id" element={<LKVideoVizardView />} />
    <Route path="did" element={<LkVideoDid />} />
    <Route path="did/:id" element={<LkVideoDid />} />
  </Routes>
}

export const LKVideo: React.FC = ()=>{
  const navigate = useNavigate()
  const  location = useLocation()

  const items = [
    {
      key: '/lk/video/vizard',
      label: <span style={{fontWeight: 600}}>Создание клипов из видео</span>, 
      path: '/lk/video/vizard',
      children: <TabChildren />
    },
    {
      key: '/lk/video/did',
      label: <span style={{fontWeight: 600}}>Видео с аватаром</span>,
      path: '/lk/video/did',
      children: <TabChildren />
    }
  ]  

  const handleChange = (e: any)=>{
    navigate(e)
  }

  
  return <Tabs defaultActiveKey={location.pathname} items={items} onChange={handleChange}/>
}