import { UserOutlined } from '@ant-design/icons';
import { Avatar } from 'antd';
import React from 'react';

import './AvatarContainer.scss'
import { useGetCurrentUserQuery } from '../../../store/userApiSlice';

type PropsType = {
  vertical?: boolean
}
const AvatarContainer: React.FC<PropsType> = ({ vertical=false }) => {
  const {data: user} = useGetCurrentUserQuery({})


  return <div className='avatarContainer'>
  <span>
      <Avatar style={{ backgroundColor: '#87d068', color: '#000000' }} shape='circle' 
      icon={<UserOutlined style={{marginTop: '4px'}}/>}
      >
      {user?.profile?.first_name?.substring(0,1)}
      {user?.profile?.last_name?.substring(0,1)}
      </Avatar>
  </span>
  <span className='username_container'>
    {!vertical && 
      <>
      {user?.profile?.first_name}&nbsp;{user?.profile?.last_name}
      </>
    }
    {vertical && 
      <>
      <span>{user?.profile?.first_name}</span>
      <span>{user?.profile?.last_name}</span>
      </>
    }
  </span>
  </div>
}

export default AvatarContainer


