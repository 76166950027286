
type BreadcrumbItemType = {
  title: string,
  subtitle?: string,
  test: RegExp|string
}

export const breadcrumbsItems: BreadcrumbItemType[]  = [
      {
        title: 'Логи пользователя', 
        test: /\/admin\/user\/\d/,
      },
      {
        title: 'Пользователи', 
        test: /\/admin\/users/,
      },
      {
        title: 'Логи', 
        test: /\/admin\/logs\/\d+/,
      },
      {
        title: 'История генераций', 
        test: '/admin/chats',
      },
      {
        title: 'Нейросети / Аккаунты', 
        test: '/admin/neuronets/accounts',  
      },
      {
        title: 'Нейросети / Примеры', 
        test: '/admin/neuronets/examples',  
      },
      {
        title: 'Нейросети / Опции', 
        test: '/admin/neuronets/options',  
      },
      {
        title: 'Статистика',
        test: /\/admin\/statistics/
      },
      {
        title: 'Заявки в поддержку',
        test: /\/admin\/support/
      },
      {
        title: 'История генераций', 
        subtitle: '',
        test: '/admin/history'
      },  
      {
        title: 'История генераций', 
        subtitle: 'Работа с текстом',
        test: '/admin/history/text'
      },  
      {
        title: 'История генераций', 
        subtitle: 'Генерация изображений',
        test: '/admin/history/images'
      },  
      {
        title: 'История генераций', 
        subtitle: 'Генерация аудио',
        test: '/admin/history/audio/synthesis'
      },
      {
        title: 'История генераций', 
        subtitle: 'Распознавание речи',
        test: '/admin/history/audio/recognition'
      },      
      {
        title: 'Чат', 
        test: 'chat/:id'
      },  
      {
        title: 'Инструменты',
        test: '/lk'
      },
      {
        title: 'Работа с текстом',
        test: '/lk/text'
      },
      {
        title: 'Работа с текстом',
        test: /\/lk\/text\/\d+\/\d+\/\d+/
      },
      {
        title: 'Работа с текстом',
        test: /\/lk\/text\/\d+\/\d+/
      },
      {
        title: 'Генерация изображений',
        test: '/lk/images'
      },
      {
        title: 'Генерация изображений',
        test: /\/lk\/images\/\d+\/\d+/
      },
      {
        title: 'Генерация аудио', 
        test: '/lk/audio/synthesis',
      },
      {
        title: 'Генерация аудио', 
        test: '/lk/audio/recognition',
      },
      {
        title: 'История генераций',
        test: '/lk/history'
      },
      {
        title: 'История генераций',
        subtitle: 'Работа с текстом',
        test: '/lk/history/text'
      },
      {
        title: 'История генераций',
        subtitle: 'Генерация изображений',
        test: '/lk/history/images'
      },
      {
        title: 'История генераций',
        subtitle: 'Генерация аудио',
        test: '/lk/history/audio/synthesis'
      },
      {
        title: 'История генераций',
        subtitle: 'Генерация аудио',
        test: '/lk/audio/recognition'
      },
      {
        title: 'История генераций',
        subtitle: 'Генерация видео',
        test: '/lk/history/video'
      },
      {
        title: 'История генераций',
        subtitle: 'Генерция видео с аватаром',
        test: '/lk/history/did'
      },

      {
        title: 'Работа с видео',
        // subtitle: 'Создание клипов из видео',
        test: '/lk/video/vizard'
      },
      {
        title: 'Работа с видео',
        // subtitle: 'Генерция видео с аватаром',
        test: '/lk/video/did'
      },

      
      {
        title: 'Заявки в поддержку',
        test: '/lk/support/all'
      },
      {
        title: 'Редактирование справки',
        test: /\/admin\/faq\/.*/
      }
      
    ]
 