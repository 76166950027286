import { useDispatch, useSelector } from "react-redux";
import { useLocation, useMatch, useMatches, useNavigate, useNavigation } from "react-router-dom";
import { getActivepage, setActivePage } from "../../../store/layoutSlice";
import { useEffect } from "react";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { breadcrumbsItems } from "./BreadcrumsItems";

export const BreadcrumbsContainer: React.FC = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const activePage = useSelector(getActivepage)
  const navigate = useNavigate();

  useEffect(() => {
    
    const crumb = breadcrumbsItems.find(item=>{
      if(item.test.constructor == RegExp){
        if(item.test.test(location.pathname)) return true
      }
      if(typeof(item.test) ==='string' && item.test===location.pathname){
        return true
      }
      return false
    })

    if(crumb) {
      dispatch(setActivePage({title: crumb.title, subtitle: crumb.subtitle}))    
    }

  }, [location]);

  return <span className="breadcrumbs">
          {activePage && (
            <span>
              <span className="breadcrumbs_title">{activePage.title}</span>
              {activePage.subtitle && <span className="breadcrumbs_subtitle">{activePage.subtitle}</span>}
            </span>
          )}
        </span>
}