// import { Popconfirm, Table, Tooltip } from "antd";
import React from "react";
import { useParams } from "react-router-dom";
import { AdminHistoryTextGenerations } from "./AdminHistoryTextGenerations";
import { AdminHistoryImageGenerations } from "./AdminHistoryImageGenerations";
import { AdminHistoryAudioGenerations } from "./AdminHistoryAudioGenerations";
import { AdminHistoryVizardGenerations } from "./AdminHistoryVizardGenerations";
import { AdminHistoryDidGenerations } from "./AdminHistoryDidGenerations";

export const AdminHistory: React.FC = () => {
  const { type } = useParams()


  return (<>
    {(type==='text' || !type) && <AdminHistoryTextGenerations showFull={type==='text'} /> }
    {(type==='images' || !type) && <AdminHistoryImageGenerations showFull={type==='images'} />}
    {(type==='audio' || !type) && <AdminHistoryAudioGenerations showFull={type==='audio'} />}
    {(type==='video' || !type) && <AdminHistoryVizardGenerations showFull={type==='video'} />}
    {(type==='did' || !type) && <AdminHistoryDidGenerations showFull={type==='did'} />}
  </>);
};
