import { AudioType } from "./Audio.type";
import { ImageType } from "./Image.type";
import { User } from "./User.type";
import { VideoType } from "./Video.type";

export type DidRequestType = {
  presenter_id: string | null;
  subtitles: boolean;
  type_provider: string;
  watermark?: boolean
  watermark_id?: number|null,
  voice_id: string | null;
  language: string;
  input_text: string;
  format: string|null;
  image_background: number | null;
  width_watermark: number | null;
  height_watermark: number | null;
};

export type DidAvatarVoiceValueType = {
  avatar: string | null,
  voice: string | null
}

export type PreseterItemType = {
  created_at: string
  gender: "female"|"male"
  id: number
  image_url: string
  is_show_telegram: boolean
  is_streamable: boolean
  name: string
  owner_id: "PUBLIC_D-ID"
  presenter_id: string
  preview_url: string
  talking_preview_url: string
  title: string
  updated_at: string|null
  video_url: string|null
}

export type VoiceItemType = {
access:  string
age: string // "middle-aged"
created_at:  string
description: string
gender: "female"|"male"
id: number
is_legacy: boolean|null
is_show_telegram: boolean
languages: any //[{config: {modelId: "eleven_multilingual_v2"}, locale: "zh-CN", language: "Chinese"},…]
name: string
provider:  string
ru_example_audio: string
styles: []
title: string
updated_at: string
use_case: null
voice_id: string
voice_type: string|null
}

export enum DidStatusEnum {
  NOT_CREATED = 'NOT_CREATED',
  PROJECT_CREATED = 'PROJECT_CREATED',
  PROJECT_PROCESS_GENERATE = 'PROJECT_PROCESS_GENERATE',
  LAST_ATTEMPT_GENERATE = 'LAST_ATTEMPT_GENERATE',
  GENERATE_DONE = 'GENERATE_DONE',
  VIDEO_UPLOADED = 'VIDEO_UPLOADED',
  ERROR_CREATE_PROJECT = 'ERROR_CREATE_PROJECT',
  ERROR_CREATE_ANSWER='ERROR_CREATE_ANSWER'
}


export type DidItemType = {
  created_at: string
  updated_at: string
  id: number
  source_image_url: string|null
  source_downloaded: boolean
  downloaded_image_url: string | null
  watermark_url: string|null
  project_id: string|null
  project_created: string|null
  project_status: DidStatusEnum
  format: "mp4"|"3gp"|"mov"|"avi"
  language: "Russian (Russia)"
  type_provider: string
  presenter_id: string
  voice_id: string
  comment: string|null
  text: string
  subtitle: boolean
  video_url: object
  user_id: number
  webchat_id: number
  result_video_id: number|null
  result_audio_id: number|null
  result_thumbnail_id: number|null
  user: User,
  presenter: PreseterItemType
  voice: VoiceItemType
  result_audio: AudioType | null,
  result_thumbnail: ImageType | null,
  result_video: VideoType|null
  is_favorite: boolean
}