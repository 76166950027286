import TextArea from "antd/es/input/TextArea";
import React, { useState } from "react";

import "./LkVideoDid.scss";
import { Button, GetProp, Input, Switch, Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import { UploadProps } from "antd/lib";
import { useAppSelector } from "../../../store/store";
import { DidAvatarVoiceValueType, DidRequestType, PreseterItemType, VoiceItemType } from "../../../types/Did.type";
import { LkVideoDidLibrary } from "./LkVideoDidLibrary";
import { useCreateDidProjectRequestMutation, useGetPresentersQuery, useGetVoicesQuery } from "../../../store/didApiSlice";
import { ImageType } from "../../../types/Image.type";
import { DidGenerations } from "./DidGenerations";
import { DidVideoPlayer } from "./DidVideoPlayer";

type FileType = Parameters<GetProp<UploadProps, "beforeUpload">>[0];

export const LkVideoDid: React.FC = () => {
  const [openedDidLibrary, setOpenDidLibrary] = useState<boolean>(false);
  const [didAvatarVoiceValue, setDidAvatarVoice] = useState<DidAvatarVoiceValueType>({avatar: null, voice: null});
  const { data: presenters, isLoading: isLoadingPresenters } = useGetPresentersQuery({});
  const { data: voices, isLoading: isLoadingVoices } = useGetVoicesQuery({});

  const [ didBackgroundImage, setDidBackground] = useState<ImageType>() 
  const [ didWatermarkImage, setDidWatermark] = useState<ImageType>() 

  const [didRequest, setDidRequest] = useState<DidRequestType>({
    presenter_id: null,
    subtitles: false,
    type_provider: "",
    watermark: false,
    watermark_id: null,    
    voice_id: null,
    language: "ru",
    input_text: "",
    format: null,
    image_background: null,
    width_watermark: null,
    height_watermark: null,
  });
  const [gender, setGender] = useState<string>('');
  const [createDidProject, {}] = useCreateDidProjectRequestMutation()
  
  const sendRequest = ()=>{
    const payload = Object.assign({}, didRequest)
    if(didRequest.watermark && didWatermarkImage) {
      payload.watermark_id = didWatermarkImage.id
    }
    if(didBackgroundImage) {
      payload.image_background = didBackgroundImage.id
    }

    createDidProject(payload)
  }


  const openDidLibrary = ()=>{
    setDidAvatarVoice({
      avatar: didRequest.presenter_id,
      voice: didRequest.voice_id
    })
    setOpenDidLibrary(true)
  }
  const closeDidLibrary = ()=>{
    setOpenDidLibrary(false)
  }
  const changeDidLibrary = (data: DidAvatarVoiceValueType)=>{
    setDidRequest({...didRequest, presenter_id: data.avatar, voice_id: data.voice})
  }
  const handleChangeGender = (gender: string)=>{
    setGender(gender)
    setDidRequest({...didRequest, voice_id: null, presenter_id: null})
  }  

  const token = useAppSelector((state) => state.auth.token);
  
  const uploadButton = (
    <Button style={{flex: 1}} icon={<UploadOutlined />}>
      Загрузить
    </Button>
  );

  const uploadWatermarkButton = (
    <Button disabled={!didRequest.watermark} style={{flex: 1}} icon={<UploadOutlined />}>
      Загрузить
    </Button>
  );


  const handleChangeUploadDidBackground: UploadProps["onChange"] = (info) => {
    if (info.file.status === "uploading") {
      // setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      const img: ImageType = info.file.response.find((i: any) => true);
      setDidBackground(img)
      // setLoading(false);
      // refetch();
      // setImageUrl(img.url);
      // onUploadComplete(img)
    }
  };
  const beforeUploadDidBackground = (file: FileType) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      // message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      // message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const handleChange: UploadProps["onChange"] = (info) => {
    if (info.file.status === "uploading") {
      // setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.
      const img = info.file.response.find((i: any) => true);
      // setLoading(false);
      // refetch();
      setDidWatermark(img);
      // onUploadComplete(img)
    }
  };
  const beforeUpload = (file: FileType) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      // message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      // message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const getVoiceTitleById = (voice_id?: string | null) => {
    if(voices && voice_id){
      const voice = voices.find((voice_item: VoiceItemType) =>voice_item.voice_id===voice_id)
      if(voice) return voice.title
    }
    return ''
  }
  const getPresenterTitleById = (presenter_id?: string | null) => {
    if(presenters && presenter_id){
      const presenter = presenters.find((voice_item: PreseterItemType) =>voice_item.presenter_id===presenter_id)
      if(presenter) return presenter.title
    }
    return ''
  }

  const disableSubmit = ()=>{
    if(!didRequest.input_text) return true
    if(!didRequest.format) return true
    if(!didRequest.presenter_id) return true
    if(!didRequest.voice_id) return true
    
    return false
  }

  return (<>
    <div className="lkVideoDid">
      <div className="lkVideoDidTextContainer">
        <div className="lkVideoDidTextLabel margined ">Текст для видео</div>
        <div className="lkVideoDidTextarea margined ">
          <TextArea
            rows={4}
            value={didRequest.input_text}
            placeholder="Вода — это стихия, которая окружает нас повсюду, являясь основой жизни на Земле."
            onChange={(val) =>
              setDidRequest({ ...didRequest, input_text: val.target.value })
            }
          />
        </div>
      </div>
      <div className="lkVideoDidPropertiesContainer">
        <div className="lkVideoDidPropertiesLine">
          <div className="lkVideoDidPropertyContainer">
            <div className="lkVideoDidPropertyLabel margined  splitter">Пол аватара</div>
            <div className="lkVideoDidProperty margined ">
              <Button onClick={(val) => handleChangeGender('M')} 
              type={gender==='M'?'primary':'default'}>Мужской</Button>
              <Button  onClick={(val) => handleChangeGender('F')} 
              type={gender==='F'?'primary':'default'}>Женский</Button>
            </div>
          </div>
          <div className="lkVideoDidPropertyContainer">
            <div className="lkVideoDidPropertyLabel margined splitter">
              <span>Авторский водяной знак</span>
              <span>
                <Switch value={didRequest.watermark} onChange={(e)=>{setDidRequest({...didRequest, watermark: e})
                }} />
              </span>
            </div>
            <div className="lkVideoDidProperty margined ">
              <span className="lkVideoDidPropertySpan">
                <Upload
                  name="file"
                  action={`/api/image/upload/did_watermark`}
                  headers={{ authorization: "bearer " + token }}
                  // listType="picture-circle"
                  className="imageuploader"
                  showUploadList={false}
                  beforeUpload={beforeUpload}
                  onChange={handleChange}>
                  {uploadWatermarkButton}
                </Upload>
              </span>
              <span className="lkVideoDidPropertySpan">
                <Input disabled value={didWatermarkImage?.meta?.original_filename} placeholder="png" />
              </span>
            </div>
          </div>
          <div className="lkVideoDidPropertyContainer">
            <div className="lkVideoDidPropertyLabel margined splitter">Формат видео</div>
            <div className="lkVideoDidProperty margined">
              <Button onClick={(val) => setDidRequest({ ...didRequest, format: 'mp4' })} 
              type={didRequest.format==='mp4'?'primary':'default'}>MP4</Button>
              <Button onClick={(val) => setDidRequest({ ...didRequest, format: 'mov' })} 
              type={didRequest.format==='mov'?'primary':'default'}>mov</Button>
              <Button onClick={(val) => setDidRequest({ ...didRequest, format: 'webm' })} 
              type={didRequest.format==='webm'?'primary':'default'}>webm</Button>
            </div>
          </div>
        </div>
        <div className="lkVideoDidPropertiesLine">
          <div className="lkVideoDidPropertyContainer">
            <div className="lkVideoDidPropertyLabel margined ">
              <Button disabled={!gender} style={{flex: 1}} onClick={openDidLibrary}>
                Библиотека внешности и голоса
              </Button>
            </div>
            <div className="lkVideoDidProperty margined">
              <span className="lkVideoDidPropertySpan">
                
                <Input disabled style={{flex: 1}} value={getPresenterTitleById(didRequest.presenter_id)} placeholder="Внешность" /> 
              </span>
              <span className="lkVideoDidPropertySpan">
                <Input disabled style={{flex: 1}} value={getVoiceTitleById(didRequest.voice_id)} placeholder="Голос" />
                
              </span>
            </div>
          </div>
          <div className="lkVideoDidPropertyContainer">
            <div className="lkVideoDidPropertyLabel margined  splitter">Фоновое изображение</div>
            <div className="lkVideoDidProperty margined ">
              <span className="lkVideoDidPropertySpan">
                <Upload
                  name="file"
                  action={`/api/image/upload/did_background`}
                  headers={{ authorization: "bearer " + token }}
                  // listType="picture-circle"
                  className="imageuploader"
                  showUploadList={false}
                  beforeUpload={beforeUploadDidBackground}
                  onChange={handleChangeUploadDidBackground}>
                  {uploadButton}
                </Upload>
              </span>
              <span className="lkVideoDidPropertySpan">
                <Input disabled style={{flex: 1}} value={didBackgroundImage?.meta?.original_filename} placeholder="jpg, jpeg, png"/>
              </span>
            </div>
          </div>
          <div className="lkVideoDidPropertyContainer last">
            <div className="lkVideoDidPropertyLabel subtitles">
              <span>
                Добавить субтитры
              </span>
              <span>
                <Switch value={didRequest.subtitles} onChange={(e)=>{setDidRequest({...didRequest, subtitles: e})}} />
              </span>
              </div>
            <div className="lkVideoDidProperty vertical">
              <Button disabled={disableSubmit()} onClick={sendRequest} style={{flex: 1, width:'100%'}}>
              Подтвердить
              </Button>
              <span className="lkVideoDidPropertySubsring">
              Для генерации необходимо заполнить все поля.
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="lkVideoDidResultContainer">
        <div className="lkVideoDidResultTitle">
          Результат
        </div>
        <div className="lkVideoDidResults">
          <DidGenerations />
        </div>
      </div>
    </div>
    { openedDidLibrary &&
      <LkVideoDidLibrary 
        gender={gender}
        onChange={changeDidLibrary}
        close={closeDidLibrary}
        value={didAvatarVoiceValue} 
        />
    }
    <DidVideoPlayer />
    </>
  );
};
